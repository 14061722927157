const API = Object.freeze({
  registration: 'user/registration',
  renew: 'user/renew',
  sendOtp: 'sendOTP',
  verifyOtp: 'signIn',
  stateList: 'fetch/states',
  getUserDetail: 'fetch/profile',
  verifyCaptcha: 'verifyCaptcha',
  downloadPdf: 'user/downloadPdf',
  setting: 'admin/setting',
})

export default API
