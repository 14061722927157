import { applyMiddleware, createStore, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
  key: 'persist-store',
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  rootReducer
  //   windoweme.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

const store = createStore(persistedReducer, compose(applyMiddleware(thunk)))
export const persistor = persistStore(store)
export default store
