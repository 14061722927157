import { Form, Card, Row, Col, Input, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect, useRef, useState } from 'react'
import { verifyOtpAction, setIsCaptchaVerifyOtp } from '../../store/actions/user'
import { SiteConstants } from '../../store/constants/siteconstants'
import { verifyCaptchaApi } from '../../services/captcha'
import { useDispatch, useSelector, connect } from 'react-redux'

// State
const mapState = props => ({
  mobile: props.userData.mobile,
})

const VerifyOtp = store => {
  const mobileNo = store.mobile
  const { RecaptchaKey, gigSureLabel } = SiteConstants
  const captcha = useRef()
  const [form1] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [isCaptchaVerify, setIsCaptchaVerify] = useState(false)

  useEffect(() => {
    const isTokenAvail = localStorage.getItem('token')
    const isUserIdAvail = localStorage.getItem('userId')

    if (!mobileNo) {
      window.location.replace('/login')
      return
    }
    if (isTokenAvail && isUserIdAvail) {
      window.location.replace('/dashboard')
    }
    //eslint-disable-next-line
  }, [])

  let IsCaptchaVerifyOtp = useSelector(store => store?.userData?.IsCaptchaVerifyOtp)

  useEffect(() => {
    CheckIsCaptachVerify()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    CheckIsCaptachVerify()
    //eslint-disable-next-line
  }, [IsCaptchaVerifyOtp])

  function CheckIsCaptachVerify() {
    if (IsCaptchaVerifyOtp === false) {
      setIsCaptchaVerify(false)
      form1.setFieldsValue({
        reCAPTCHA: null,
      })
      dispatch(setIsCaptchaVerifyOtp(null))
    }
    if (IsCaptchaVerifyOtp === true) {
      setIsCaptchaVerify(true)
    }
  }

  const handleSubmitForm = async value => {
    if (isCaptchaVerify) {
      const verifyData = {
        mobile: mobileNo,
        otp: value.otp,
      }
      await dispatch(verifyOtpAction({ verifyData, navigate }))
      // form.resetFields()
    }
  }

  const verifyCaptcha = async token => {
    const params = {
      token: token,
    }
    verifyCaptchaApi(params).then(async data => {
      if (data && data.status === 200) {
        await setIsCaptchaVerify(true)
      } else {
        captcha.reset()
      }
    })
  }

  const onErrorInCaptcha = async () => {
    setIsCaptchaVerify(false)
  }

  const onVerifyCaptchaCallback = response => {
    verifyCaptcha(response)
  }
  return (
    <>
      <Row gutter={2} style={{ padding: '50px 0' }}>
        <Col
          xxl={8}
          xl={8}
          lg={22}
          md={22}
          sm={24}
          xs={24}
          style={{ paddingTop: '1%', margin: '0 auto' }}
        >
          <Card
            size="default"
            style={{ borderRadius: '12px', padding: '15px' }}
            title={`Login ${gigSureLabel} Account`}
          >
            <Form
              name="basic"
              onFinish={handleSubmitForm}
              autoComplete="off"
              form={form1}
            >
              <Form.Item
                name="otp"
                rules={[
                  {
                    required: true,
                    message: 'Please enter OTP !',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Please insert valid OTP',
                  },
                ]}
              >
                <Input size="large" placeholder="Enter OTP" type="number" />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  name="reCAPTCHA"
                  rules={[
                    {
                      required: true,
                      message: 'reCAPTCHA is required',
                    },
                  ]}
                >
                  <ReCAPTCHA
                    size="normal"
                    ref={captcha}
                    sitekey={RecaptchaKey}
                    onChange={onVerifyCaptchaCallback}
                    onExpired={onErrorInCaptcha}
                    onErrored={onErrorInCaptcha}
                  />
                </Form.Item>
                <Button className="btn-bg" size="large" htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}
export default connect(mapState, null)(VerifyOtp)
