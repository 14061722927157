import {
  Descriptions,
  Badge,
  Col,
  Row,
  Typography,
  Card,
  Checkbox,
  Form,
  Button,
} from 'antd'

import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
// import retailerSign from '../../assets/image/retailerSign.jpg'
import pdfLogo from '../../assets/image/pdfLogo1.png'
import moment from 'moment'
import { SiteConstants } from '../../store/constants/siteconstants'
import _ from 'lodash'
import { printPdf } from '../../store/actions/user'
const { Title } = Typography

function Step3(props) {
  const [pdflink, setPdflink] = useState(null)
  const LoaderPrint = useSelector(store => store?.userData?.printLoader)
  const PdfData = useSelector(store => store?.userData?.pdfData)

  useEffect(() => {
    if (PdfData) {
      localStorage.setItem('application_file_name', PdfData?.data?.data?.pdfFileName)
      setPdflink(PdfData?.data?.data?.pdfFile)
    }
  }, [PdfData])

  const dispatch = useDispatch()
  const {
    name,
    gender,
    mobile,
    whatsapp_no,
    email,
    address,
    taluka,
    district,
    state,
    pin_code,
    bank_name,
    branch_name,
    account_no,
    ifsc_code,
    height,
    weight,
    business,
    monthly_income,
    disease,
    marital_status,
    nominee_name,
    relative,
    facilitator_code,
    facilitator_name,
    yojana_number,
    agent_name,
    agent_code,
    facilitator,
  } = props.form.getFieldValue()

  let sign =
    props?.form1?.getFieldValue()?.signature_image &&
    props?.form1?.getFieldValue()?.signature_image[0]?.name

  let state2Letter = props?.stateTwoLetter

  const { gigSureLabel } = SiteConstants
  const { aadharcard_number, pan_card_number } = props.form1.getFieldValue()
  const onFinish = values => {
    if (_.has(values, 'agreement') && values?.agreement) {
      props?.setCurrentStep(props?.currentStep + 1)
    }
  }

  useEffect(() => {
    async function dataPrint() {
      const formData2 = new FormData()

      formData2.append('date_of_application', moment(new Date()) || 'N/A')
      formData2.append('full_name', name?.toUpperCase() || 'N/A')
      formData2.append(
        'date_of_birth',
        (props.form.getFieldValue().dob && props.form.getFieldValue().dob._d) || 'N/A'
      )
      formData2.append('gender', gender?.toUpperCase() || 'N/A')
      formData2.append('contact_number', mobile?.toString() || 'N/A')
      formData2.append('whatsapp_number', whatsapp_no?.toString() || 'N/A')
      formData2.append('email', email?.toUpperCase() || 'N/A')
      formData2.append('full_address', address?.toUpperCase() || 'N/A')
      formData2.append('taluka', taluka?.toUpperCase() || 'N/A')
      formData2.append('district', district?.toUpperCase() || 'N/A')
      formData2.append('state', state?.toUpperCase() || 'N/A')
      formData2.append('pincode', pin_code?.toString() || 'N/A')
      formData2.append('aadhaar_card_number', aadharcard_number?.toString() || 'N/A')
      formData2.append('pan_card_number', pan_card_number?.toString() || 'N/A')
      formData2.append('bank_name', bank_name?.toUpperCase() || 'N/A')
      formData2.append('branch_name', branch_name?.toUpperCase() || 'N/A')
      formData2.append('bank_account_number', account_no?.toString() || 'N/A')
      formData2.append('ifsc_code', ifsc_code?.toUpperCase() || 'N/A')
      formData2.append('height', height?.toString() || 'N/A')
      formData2.append('weight', weight?.toString() || 'N/A')
      formData2.append('occupation', business?.toString() || 'N/A')
      formData2.append(
        'net_monthly_income',
        (monthly_income === 'BELOW 5000' && 'BELOW ₹5000') ||
          (monthly_income === '5000 TO 10000' && '₹5000 TO ₹10000') ||
          (monthly_income === '10000 & ABOVE' && '₹10000 & ABOVE') ||
          'N/A'
      )
      formData2.append('pre_disease', disease?.toString() || 'N/A')
      formData2.append('marital_status', marital_status?.toString() || 'N/A')
      formData2.append('nominee_relation', relative?.toString() || 'N/A')
      formData2.append('nominee_name', nominee_name?.toString() || 'N/A')
      formData2.append(
        'nominee_dob',
        (props.form.getFieldValue().nominee_dob &&
          props.form.getFieldValue().nominee_dob._d) ||
          'N/A'
      )
      formData2.append('ma_yojana_number', yojana_number?.toString() || 'N/A')
      formData2.append('facilitator', facilitator?.toString() || 'N/A')

      if (props?.hideAgent === true) {
        formData2.append('facilitator_name', facilitator_name?.toUpperCase())
      }

      if (props?.hideFacilitator === true) {
        formData2.append('agent_name', agent_name?.toUpperCase())
      }

      if (props?.hideAgent === true) {
        formData2.append(
          'facilitator_code',
          state2Letter.concat(_.upperCase(facilitator_code))
        )
      }

      if (props?.hideFacilitator === true) {
        formData2.append('agent_code', 'GS' + _.upperCase(agent_code))
      }

      formData2.append('signature_image', props?.signatureImageData)

      const UserData = localStorage.getItem('userId')
      if (UserData) {
        formData2.append('userId', UserData)
      }

      await dispatch(
        printPdf({
          formData2,
        })
      )
    }
    dataPrint()
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Row className="mt-2">
        <Col span={24}>
          <Title level={3} className="text-center">
            गिग्स्योर सदस्यता फॉर्म:
          </Title>
        </Col>
      </Row>
      <Row gutter={10}>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          <Descriptions title="" bordered>
            <Descriptions.Item label="Date of application" span={3}>
              <Badge
                status="processing"
                text={moment(new Date()).format('DD/MM/YYYY') || 'N/A'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Full name" span={3}>
              <Badge status="processing" className="uppercase" text={name || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Date of birth" span={3}>
              <Badge
                status="processing"
                text={`${
                  (props.form.getFieldValue().dob &&
                    moment(props.form.getFieldValue().dob._d).format('DD/MM/YYYY')) ||
                  'N/A'
                }`}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Gender" span={3}>
              <Badge status="processing" text={gender || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Contact number" span={3}>
              <Badge status="processing" text={mobile || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Whatsapp number" span={3}>
              <Badge status="processing" text={whatsapp_no || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Email" span={3}>
              <Badge status="processing" className="uppercase" text={email || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Full address as per UID (Village / Town)" span={3}>
              <Badge status="processing" className="uppercase" text={address || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Taluka" span={3}>
              <Badge status="processing" className="uppercase" text={taluka || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="District" span={3}>
              <Badge status="processing" className="uppercase" text={district || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="State" span={3}>
              <Badge status="processing" className="uppercase" text={state || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Pincode" span={3}>
              <Badge status="processing" text={pin_code || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="UID / Aadhaar card number" span={3}>
              <Badge status="processing" text={aadharcard_number || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="PAN card number" span={3}>
              <Badge
                status="processing"
                className="uppercase"
                text={pan_card_number || 'N/A'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Claim mgt. - Bank and location" span={3}>
              <Badge
                className="gig-custom-badge"
                status="processing"
                text={
                  <div>
                    <span>Bank name: {_.upperCase(bank_name) || 'N/A'}</span>
                    <br />
                    <span>Branch name: {_.upperCase(branch_name) || 'N/A'}</span>
                  </div>
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label="Bank account number" span={3}>
              <Badge status="processing" text={account_no || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="IFSC code" span={3}>
              <Badge
                status="processing"
                className="uppercase"
                text={ifsc_code || 'N/A'}
              />
            </Descriptions.Item>
            <Descriptions.Item label="Height (inch) " span={3}>
              <Badge status="processing" text={height || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Weight (Kg) " span={3}>
              <Badge status="processing" text={weight || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Occupation" span={3}>
              <Badge status="processing" className="uppercase" text={business || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Net monthly income" span={3}>
              <Badge
                status="processing"
                text={
                  (monthly_income === 'BELOW 5000' && 'BELOW ₹5000') ||
                  (monthly_income === '5000 TO 10000' && '₹5000 TO ₹10000') ||
                  (monthly_income === '10000 & ABOVE' && '₹10000 & ABOVE') ||
                  'N/A'
                }
              />
            </Descriptions.Item>
            <Descriptions.Item label="Pre disease" span={3}>
              <Badge status="processing" className="uppercase" text={disease || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Marital status" span={3}>
              <Badge status="processing" text={marital_status || 'N/A'} />
            </Descriptions.Item>
            <Descriptions.Item label="Nominee details" span={3}>
              <Badge
                status="processing"
                text={`Name : ${_.upperCase(nominee_name) || 'N/A'}`}
              />
            </Descriptions.Item>
            <Descriptions.Item label="" span={3}>
              <Badge
                status="processing"
                text={`Date of birth : ${
                  (props.form.getFieldValue().nominee_dob &&
                    moment(props.form.getFieldValue().nominee_dob._d).format(
                      'DD/MM/YYYY'
                    )) ||
                  'N/A'
                }`}
              />
            </Descriptions.Item>
            <Descriptions.Item label="" span={3}>
              <Badge
                status="processing"
                text={`Relation with member : ${relative || 'N/A'}`}
              />
            </Descriptions.Item>

            <Descriptions.Item label="MA Yojana number" span={3}>
              <Badge
                status="processing"
                className="uppercase"
                text={yojana_number || 'N/A'}
              />
            </Descriptions.Item>
          </Descriptions>

          <Card>
            {props?.hideFacilitator === true && props?.hideAgent === true ? (
              ''
            ) : (
              <>
                <p className="mb0">
                  {props?.hideAgent === true &&
                    'सुविधाकर्ता नाम: ' + facilitator_name?.toUpperCase()}

                  {props?.hideFacilitator === true &&
                    'ऐजन्ट नाम: ' + agent_name?.toUpperCase()}
                </p>

                <p>
                  {props?.hideAgent === true &&
                    'सुविधाकर्ता कोड: ' +
                      state2Letter.concat(_.upperCase(facilitator_code))}

                  {props?.hideFacilitator === true &&
                    'ऐजन्ट कोड: ' + 'GS' + _.upperCase(agent_code)}
                </p>
              </>
            )}

            <p>
              मैं इसके द्वारा घोषणा करता हूं कि मैं &#x22;ईआरएमपीएल ग्राम-हाट&#x22; का
              ग्राहक हूं और ईआरएमपीएल गिगस्योर वार्षिक सदस्यता कार्यक्रम का सदस्य बनने के
              लिए सहमत हूं। प्रस्तुत किए गए सभी ब्यौरे और दस्तावेज मेरी जानकारी के अनुसार
              हैं। मैं नियमों/शर्तों को समझता हूं और गिगस्योर सदस्यता कार्यक्रम के सभी
              लाभों के पात्रताके लिए सहमत हूं।
            </p>

            <p className="mb0">
              <u>वार्षिक कार्यक्रम के तहत प्राप्त लाभ </u>
            </p>

            <ul className="banifite_list">
              <li>
                1. अस्पताल की देखभाल: एक साल में 30 दिनों के लिये दैनी Rs. 500 / दिन
                मुआवजा(अस्पताल में भर्ती के ४८ घंटे)
              </li>
              <li>2. एक्सीडेंटल केयर: मेडिकल खर्च कुल वार्षिक Rs. 25,000</li>

              <li>
                3. फैमिली केयर: पर्सनल एक्सीडेंटल कवर - Rs.1,00,000 रुपये (स्थायी शारीरिक
                हानि/मृत्यु ) + एम्बुलेंस सुविधा Rs. 1000 तक
              </li>
              <li>
                4. आपातकालीन देखभाल: मृत शरीर को मातृभूमि तक पहुंचना + मृत्यु परियन्त विधि
                के लिये Rs. 5000
              </li>
              <li>
                5. स्वास्थ्य देखभाल: स्वास्थ्य जाांच/पैथोलोजिकल टेस्ट - आकर्षक डिस्काउंट
                पर : 40% तक
              </li>
              <li>
                6. चिकित्सा देखभाल: दवाइयों पे डिस्काउंट के साथ मुफ्त होम डिलीवरी : 20% तक
              </li>
              <li>7. डॉक्टर की देखभाल: मुफ्त डॉक्टर-ऑन-कॉल सुविधा (साल में 5 बार)</li>
              <li>
                8. वित्तीय/माइक्रोफाइनेंस केयर : मेडिकल इमरजेंसी फाइनेंस: Rs. 50,000 तक +
                पूर्व-अनुमोदित : पर्सनल लोन - Rs. 50,000 तक + लघु व्यवसाय ऋण / आवास ऋण:
                करोड़ Rs. 2,50,000 तक
              </li>
              <li>11. Rs. 399 मुल्यका 24 डिजिटल मैगेज़ीनका वार्षिक सदस्यता</li>
            </ul>

            <p className="mb0">
              <u>
                हर लाभ की पात्रताके लिए {gigSureLabel} पार्टनरकी नियम और शर्ते के आधीन है
                और कृपया पत्रोंको ध्यानसे पढ़े.
              </u>
            </p>
            <p>
              आप 9099160373 पर किसी भी सहायता के लिए कॉल कर सकते हैं.
              <br />
              हम आपके स्वास्थ्य की ख़ुशीकी कामना करते हैं. व्यक्तिगत स्वास्थ्य देखभाल एवं
              सुखकारीकी और क्रन्तिकारी क़दम : {gigSureLabel}.
            </p>

            <p>
              सदस्य: हस्ताक्षर &nbsp;
              {sign && sign?.split('.').pop().slice(0, 3) === 'pdf' ? (
                <a href={props?.signature} target="_blank" rel="noopener noreferrer">
                  <img src={pdfLogo} alt="signature image" style={{ width: 300 }} />
                </a>
              ) : (
                <img
                  className="signatureS3"
                  src={props?.signature}
                  alt="signature image"
                  style={{ width: '100%' }}
                />
              )}
            </p>
            <p>
              रिटेलर: इस्ताक्षर
              {/* <img src={retailerSign} alt="signature" style={{ width: 300 }} /> */}
            </p>
            <p>
              || ग्राम हाट ||&nbsp; &nbsp; &nbsp;
              <b> eRenovision Management (I) Pvt. Ltd.:An affinity membership program</b>
            </p>

            <div>
              <Col span={24} className="mt-3 mb-3" style={{ textAlign: 'right' }}>
                <a href={pdflink} rel="noreferrer" download={pdflink}>
                  <Button
                    className="btn-bg"
                    shape="round"
                    size="large"
                    loading={LoaderPrint}
                  >
                    Download PDF
                  </Button>
                </a>
              </Col>
            </div>
          </Card>

          <Form name="verificationForm" onFinish={onFinish}>
            <Form.Item
              name="agreement"
              valuePropName="checked"
              className="agreementClass2"
              rules={[
                {
                  validator: (_, value) =>
                    value
                      ? Promise.resolve()
                      : Promise.reject(new Error('Please accept terms and conditions')),
                },
              ]}
            >
              <Checkbox className="agreementClass3">
                I hereby declare that information and documents submitted for&nbsp;
                {gigSureLabel} Membership are as per my full knowledge. I give consent to
                approve my application as per {gigSureLabel} membership policy.
              </Checkbox>
            </Form.Item>
            <Row gutter={10}>
              <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'left' }}>
                <Button
                  onClick={() => {
                    props.setIsCaptchaVerify(false)
                    props?.setCurrentStep(props?.currentStep - 1)
                  }}
                  className="place-order-button w-50 back-btn"
                >
                  Back
                </Button>
              </Col>
              <Col span={12} className="mt-3 mb-3" style={{ textAlign: 'right' }}>
                <Button className="place-order-button w-50" htmlType="submit">
                  Next
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  )
}

export default Step3
