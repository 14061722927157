import { USER } from '../constants'
const {
  REGISTRATIONLOADER,
  STATELIST,
  SENDOTP,
  VERIFYOTP,
  GETUSERDETAILS,
  SAVEMOBILE,
  PRINT_LOADER,
  PRINT_DATA_STORE,
  SETTING_DATA_STORE,
  SET_IS_CAPTCHA_VERIFY_LOGIN,
  SET_IS_CAPTCHA_VERIFY_OTP,
  STORE_VALID_TILL_DATE,
} = USER

// export interface UserState {
//   loggedInUserData: any;
//   registrationLoader: Boolean;
//   allStats: Array;
// }

const initialState = {
  loggedInUserData: [],
  registrationLoader: false,
  allStats: [],
  sendOtp: null,
  singIn: null,
  userDetails: [],
  mobile: null,
  printLoader: false,
  pdfData: null,
  setting_data: null,
  IsCaptchaVerifyLogin: null,
  IsCaptchaVerifyOtp: null,
  validTill: null,
}

function userData(state = initialState, action) {
  switch (action.type) {
    case SAVEMOBILE:
      return {
        ...state,
        mobile: action.params,
      }
    case REGISTRATIONLOADER:
      return {
        ...state,
        registrationLoader: action.data,
      }

    case PRINT_LOADER:
      return {
        ...state,
        printLoader: action.data,
      }

    case STATELIST:
      return {
        ...state,
        allStats: action.data,
      }
    case SENDOTP:
      return {
        ...state,
        sendOtp: action.data,
      }
    case VERIFYOTP:
      return {
        ...state,
        singIn: action.data,
      }
    case GETUSERDETAILS:
      return {
        ...state,
        userDetails: action.data,
      }

    case PRINT_DATA_STORE:
      return {
        ...state,
        pdfData: action.data,
      }

    case SETTING_DATA_STORE:
      return {
        ...state,
        setting_data: action.data,
      }

    case SET_IS_CAPTCHA_VERIFY_LOGIN:
      return {
        ...state,
        IsCaptchaVerifyLogin: action.data,
      }

    case SET_IS_CAPTCHA_VERIFY_OTP:
      return {
        ...state,
        IsCaptchaVerifyOtp: action.data,
      }

    case STORE_VALID_TILL_DATE:
      return {
        ...state,
        validTill: action.data,
      }

    default:
      return state
  }
}

export default userData
