import { Link } from 'react-router-dom'
import { Result } from 'antd'

const NotFound = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={<Link to="/">Go To The Homepage</Link>}
  />
)

export default NotFound
