import { Result } from 'antd'

import moment from 'moment'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { connect } from 'react-redux'
const mapState = props => ({
  validDate: props?.userData?.validTill,
})

function Success(props) {
  const { policy_number } = useParams()
  const expDate = moment(props?.validDate).format('DD/MM/YYYY')

  useEffect(() => {
    scroll(props?.appRef)
  }, [props])

  function scroll(ref) {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="gig-registered-success-result">
      <Result
        status="success"
        title={
          <div>
            You have submitted application successfully.
            <br /> Our team is reviewing it and you will be notified via Email &amp; SMS
            once it&apos;s approved.
          </div>
        }
        subTitle={`Membership number: ${policy_number} It is valid up to ${expDate}`}
      />
    </div>
  )
}

// export default Success

export default connect(mapState, null)(Success)
