import axios from 'axios'
import CONFIG from '../config'
/*
import { history } from '../config/history'
import { message } from 'antd'
*/

const httpInstance = axios.create({
  timeout: 60000,
  baseURL: CONFIG.http.baseURL,
})

Object.setPrototypeOf(httpInstance, axios)

httpInstance.interceptors.request.use(
  function (config) {
    const { method } = config
    const data = {}

    if (method === 'post' || method === 'put') {
      if (config.data instanceof FormData) {
        for (const key in data) {
          config.data.append(key, data[key])
        }
      } else {
        config.data = Object.assign(data, config.data)
      }
    }

    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

httpInstance.interceptors.response.use(
  function (res) {
    return res
  },
  function (error) {
    /*
    if (error.response && error.response.data.code === 401 ) {
      localStorage.clear()
      message.error('401 Unauthorized Error')
      history.push('/')
    }
    */

    return Promise.reject(error)
  }
)

export default httpInstance
