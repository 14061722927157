import './App.css'
import { Layout } from 'antd'
import HeaderComponent from './components/header'
import FooterComponent from './components/footer'
import { createRef } from 'react'
import RoutesComponent from './router'
const { Content } = Layout

function App() {
  const appRef = createRef()
  return (
    <div ref={appRef}>
      <Layout>
        <HeaderComponent />
        <Content className="mainContainer">
          <RoutesComponent appRef={appRef} />
        </Content>
        <div className="footer">
          <FooterComponent />
        </div>
      </Layout>
    </div>
  )
}

export default App
