export const USER = {
  REGISTRATION: 'REGISTRATION',
  REGISTRATIONLOADER: 'REGISTRATIONLOADER',
  SENDOTP: 'SENDOTP',
  VERIFYOTP: 'VERIFYOTP',
  STATELIST: 'STATELIST',
  GETUSERDETAILS: 'GETUSERDETAILS',
  SAVEMOBILE: 'SAVEMOBILE',
  PRINT_LOADER: 'PRINT_LOADER',
  PRINT_DATA_STORE: 'PRINT_DATA_STORE',
  SETTING_DATA_STORE: 'SETTING_DATA_STORE',
  SET_IS_CAPTCHA_VERIFY_LOGIN: 'SET_IS_CAPTCHA_VERIFY_LOGIN',
  SET_IS_CAPTCHA_VERIFY_OTP: 'SET_IS_CAPTCHA_VERIFY_OTP',
  STORE_VALID_TILL_DATE: 'STORE_VALID_TILL_DATE',
}
