import { useState, useEffect } from 'react'
import './style.css'
import DynamicFooter from './DynamicFooter.module.css'
import RcFooter from 'rc-footer'
import moment from 'moment'
import 'rc-footer/assets/index.css'
// import favicon from '../../assets/image/paymentQr.png'
import footerImg from '../../assets/image/gigsure-footer.jpg'
import { SiteConstants } from '../../store/constants/siteconstants'
import { useLocation } from 'react-router-dom'

const Footer = () => {
  const { gigSureLabel } = SiteConstants
  const location = useLocation()
  const [current, setCurrent] = useState(location.pathname)

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname)
      }
    }
  }, [location, current])

  return (
    <>
      <div className="f-img bg_grey">
        <img src={footerImg} alt="" />
      </div>
      <RcFooter
        id={
          ((current === '/login' ||
            current === '/verifyOtp' ||
            current.includes('/success')) &&
            DynamicFooter.CustomFooterStyle) ||
          ''
        }
        bottom={
          <span style={{ textAlign: 'center', display: 'inherit' }}>
            © {moment().format('YYYY')} {gigSureLabel} · All Right Reserved
          </span>
        }
      />
    </>
  )
}

export default Footer
