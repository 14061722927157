import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './globalFunction'

export function RequiresAuth({ children }) {
  const auth = useAuth()
  const location = useLocation()
  if (!auth) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }
  return children
}
