import './style.css'
import { Menu, Layout, Button, Row, Col, message } from 'antd'
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import LOGO from '../../assets/image/GigSURE.png'
import { Helmet } from 'react-helmet'
import { SiteConstants } from '../../store/constants/siteconstants'
import moment from 'moment'
import { googleTranslateElementInit } from '../../utils/globalFunction'
const { Header } = Layout

const MainHeader = () => {
  // States
  const location = useLocation()
  const [current, setCurrent] = useState(location.pathname)
  const [activeClass, setActiveClass] = useState('')

  useEffect(() => {
    var addScript = document.createElement('script')
    addScript.setAttribute(
      'src',
      '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit'
    )
    document.body.appendChild(addScript)
    window.googleTranslateElementInit = googleTranslateElementInit
  }, [])

  useEffect(() => {
    if (location) {
      if (current !== location.pathname) {
        setCurrent(location.pathname)
      }
    }
  }, [location, current])

  // Life cycle hooks
  useEffect(() => {
    const handleScroll = () => {
      let active = 'active'
      if (window.scrollY === 0) {
        active = ''
      }
      setActiveClass(active)
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll)
    }
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  // 1 Min After CHECK
  useEffect(() => {
    const interval = setInterval(() => {
      let DateTimeToken = localStorage.getItem('DateTimeTokenFront')
      let CurrentDateTime = moment().format('YYYY-MM-DD HH:mm:ss')

      if (CurrentDateTime > DateTimeToken) {
        message.error('Your session is expired, please login again')
        onLogout()
      }
    }, 60000)
    return () => clearInterval(interval)
    // eslint-disable-next-line
  }, [])

  const onLogout = () => {
    window.localStorage.clear()
    window.location.href = '/login'
  }

  const CustomTitle = () => {
    if (current === '/') {
      return `Membership proposal form - ${SiteConstants.gigSureLabel}`
    } else if (current === '/login') {
      return `Login - ${SiteConstants.gigSureLabel}`
    } else if (current === '/dashboard') {
      return `Dashboard - ${SiteConstants.gigSureLabel}`
    } else {
      if (current.includes('/success')) {
        return `Success - ${SiteConstants.gigSureLabel}`
      }
      if (current.includes('/verifyOtp')) {
        return `Verify otp - ${SiteConstants.gigSureLabel}`
      }
    }
  }

  return (
    <Header className={`mainHeader ${activeClass}`}>
      <Helmet>
        <title>{CustomTitle()}</title>
      </Helmet>

      <Row>
        <Col
          xxl={16}
          xl={16}
          lg={22}
          md={22}
          sm={24}
          xs={24}
          className="header-wrapper-col"
        >
          <Link to="/">
            <div className="logo">
              <img src={LOGO} alt="" width="100%" />
            </div>
          </Link>

          <Menu mode="horizontal" defaultSelectedKeys={['2']} className="menu-right">
            {!localStorage.getItem('token') && (
              <>
                <Menu.Item key="registration" className="site-header__menu-item">
                  <Link to="/">Home</Link>
                </Menu.Item>
              </>
            )}

            {localStorage.getItem('token') ? (
              <>
                <Menu.Item key="dashboard">
                  <Link to="/dashboard">Dashboard</Link>
                </Menu.Item>
                <Menu.Item className="LogoutMenuClass">
                  <Button className="btn-bg" onClick={() => onLogout()}>
                    Logout
                  </Button>
                </Menu.Item>
              </>
            ) : (
              <>
                <Menu.Item key="login" className="site-header__menu-item">
                  <Link to="/login">Login</Link>
                </Menu.Item>
              </>
            )}
            <Menu.Item key="translate" className="site-header__menu-item">
              <span id="google_translate_element"></span>
            </Menu.Item>
          </Menu>
        </Col>
      </Row>
    </Header>
  )
}
export default MainHeader
