import { Navigate, useLocation } from 'react-router-dom'
import { useAuth } from './globalFunction'

export function CheckUserLoggedIn({ children }) {
  const auth = useAuth()
  const location = useLocation()
  if (auth) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />
  }
  return children
}
