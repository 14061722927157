import ReactDOM from 'react-dom'
import './index.css'
import 'antd/dist/antd.min.css'
import App from './App'
import { Router } from 'react-router-dom'
import { useLayoutEffect, useState } from 'react'
import { Provider } from 'react-redux'
import store, { persistor } from './store'
import { history } from './config/history'
import { PersistGate } from 'redux-persist/integration/react'

const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  })

  useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  )
}

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CustomRouter history={history}>
        <App />
      </CustomRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
