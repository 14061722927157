import { Form, Card, Row, Col, Input, Button } from 'antd'
import { useDispatch } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { otpSendAction, setIsCaptchaVerifyLogin } from '../../store/actions/user'
import { SiteConstants } from '../../store/constants/siteconstants'
import { useRef, useState } from 'react'
import { verifyCaptchaApi } from '../../services/captcha'
import './style.css'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { useEffect } from 'react'

const Login = () => {
  const [form1] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const captcha = useRef()

  const { RecaptchaKey, gigSureLabel } = SiteConstants
  const [isCaptchaVerify, setIsCaptchaVerify] = useState(false)

  let IsCaptchaVerifyLogin = useSelector(store => store?.userData?.IsCaptchaVerifyLogin)

  useEffect(() => {
    CheckIsCaptachVerify()
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    CheckIsCaptachVerify()
    //eslint-disable-next-line
  }, [IsCaptchaVerifyLogin])

  function CheckIsCaptachVerify() {
    if (IsCaptchaVerifyLogin === false) {
      setIsCaptchaVerify(false)
      form1.setFieldsValue({
        reCAPTCHA: null,
      })
      dispatch(setIsCaptchaVerifyLogin(null))
    }
    if (IsCaptchaVerifyLogin === true) {
      setIsCaptchaVerify(true)
    }
  }

  const handleSubmitForm = async value => {
    if (isCaptchaVerify) {
      await dispatch(otpSendAction({ value, navigate }))
      // form.resetFields()
    }
  }

  const verifyCaptcha = async token => {
    const params = {
      token: token,
    }
    verifyCaptchaApi(params).then(async data => {
      if (data && data.status === 200) {
        await setIsCaptchaVerify(true)
      } else {
        captcha.reset()
      }
    })
  }

  const onErrorInCaptcha = async () => {
    setIsCaptchaVerify(false)
  }

  const onVerifyCaptchaCallback = response => {
    verifyCaptcha(response)
  }
  return (
    <>
      <Row gutter={2} style={{ padding: '50px 0' }}>
        <Col
          xxl={8}
          xl={8}
          lg={22}
          md={22}
          sm={24}
          xs={24}
          style={{ paddingTop: '1%', margin: '0 auto' }}
        >
          <Card
            size="default"
            style={{ borderRadius: '12px', padding: '15px' }}
            title={`Login ${gigSureLabel} Account`}
          >
            <Form
              name="basic"
              onFinish={handleSubmitForm}
              autoComplete="off"
              form={form1}
            >
              <Form.Item
                name="mobile"
                rules={[
                  {
                    required: true,
                    message: 'Mobile number is required',
                  },
                  {
                    pattern: /^(?:\d*)$/,
                    message: 'Please insert valid mobile number',
                  },
                  {
                    max: 10,
                    min: 10,
                    message: 'Please check mobile number.',
                  },
                ]}
              >
                <Input
                  type="number"
                  placeholder="Enter mobile number"
                  size="large"
                  style={{ width: '100%' }}
                />
              </Form.Item>

              <Form.Item
                name="reCAPTCHA"
                rules={[
                  {
                    required: true,
                    message: 'reCAPTCHA is required',
                  },
                ]}
              >
                <ReCAPTCHA
                  size="normal"
                  ref={captcha}
                  sitekey={RecaptchaKey}
                  onChange={onVerifyCaptchaCallback}
                  onExpired={onErrorInCaptcha}
                  onErrored={onErrorInCaptcha}
                />
              </Form.Item>

              <Button className="btn-bg" size="large" htmlType="submit">
                Submit
              </Button>
            </Form>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default Login
