import api from '../api'
import http from '../utils/http'

// register
export async function registerUser(params) {
  const config = {
    headers: { 'content-type': 'multipart/form-data' },
  }
  return http.post(api.registration, params, config)
}

//  renew
export async function renewUser(params) {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'content-type': 'multipart/form-data',
    },
  }

  return http.put(api.renew, params, config)
}

export async function otpSend(params) {
  return http.post(api.sendOtp, params)
}
export async function signIn(params) {
  return http.post(api.verifyOtp, params)
}
export async function getUserDetails(params) {
  return http.get(api.getUserDetail, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: params,
  })
}
export async function stateList(params) {
  return http.get(api.stateList, params)
}

export async function printPdfAction(params) {
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  }
  return http.post(api.downloadPdf, params, config)
}

export async function settingData() {
  return http.get(api.setting)
}
