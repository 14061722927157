import { useEffect } from 'react'
import './style.css'
import { Row, Col, Button, Typography, Card, Image, Tag } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { DownloadOutlined, RedoOutlined } from '@ant-design/icons'
import LOGO from '../../assets/image/Logo2.png'
import { getUserDetailsAction } from '../../store/actions/user'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'

export function NotAvailablePDf(params) {
  return (
    <>
      <Col
        xs={{
          span: 24,
        }}
        xl={{
          offset: 2,
          span: 4,
        }}
        lg={{
          span: 8,
        }}
        md={{
          span: 12,
        }}
        className="dashbord-grid notFoundComponent"
      >
        <Card bordered={false}>
          <Card className="dashboardCard" bordered={false}>
            {params?.name}
          </Card>
        </Card>
      </Col>
    </>
  )
}

function Dashboard({ userDetails }) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { Title } = Typography
  const { Meta } = Card

  useEffect(() => {
    dispatch(getUserDetailsAction(localStorage.getItem('userId')))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    window.addEventListener('popstate', () => {
      history.go(1)
    })
  }, [])

  return (
    <>
      <div className="dashboard_wrapper">
        <Row align="center">
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <img className="contain-logo mb-30" src={LOGO} alt="" />

            <Title level={5} className="mb-30">
              <Tag color="default" className="dashboard_label">
                Name : {userDetails?.fetchUser?.name}
              </Tag>
              <br />
              <br />
              <Tag color="default" className="dashboard_label">
                Membership Number : {userDetails?.fetchUser?.policy_number}
              </Tag>

              {moment(new Date()).isAfter(userDetails?.expDate) && (
                <>
                  <br />
                  <br />
                  <Button
                    className="btn-bg"
                    shape="round"
                    icon={<RedoOutlined />}
                    size="large"
                    onClick={() => navigate('/')}
                  >
                    Renew Plan
                  </Button>
                </>
              )}
            </Title>
          </Col>
        </Row>
        <Row gutter={10} className="mb-50">
          <Col
            xs={{
              span: 24,
            }}
            xl={{
              offset: 1,
              span: 4,
            }}
            lg={{
              span: 8,
            }}
            md={{
              span: 12,
            }}
            className="dashbord-grid"
          >
            {userDetails?.fetchUser?.certificate_image ? (
              <Card title="" bordered={true}>
                <a
                  href={userDetails?.fetchUser?.certificate_image}
                  target="_blank"
                  rel="noreferrer"
                  download={userDetails?.fetchUser?.certificate_image}
                >
                  <Card
                    hoverable
                    cover={
                      <Image
                        preview={false}
                        src={userDetails?.fetchUser?.certificate_image}
                        alt="Certificate Image"
                      />
                    }
                  >
                    <Meta title="Certificate Image" />
                  </Card>
                </a>
              </Card>
            ) : (
              <NotAvailablePDf name="Certificate Image Not Available" />
            )}
          </Col>

          <Col
            xs={{
              span: 24,
            }}
            xl={{
              offset: 2,
              span: 4,
            }}
            lg={{
              span: 8,
            }}
            md={{
              span: 12,
            }}
            className="dashbord-grid div2"
          >
            {userDetails?.fetchUser?.certificate_pdf ? (
              <Card bordered={true} hoverable>
                <Card
                  className="dashboardCard"
                  bordered={false}
                  cover={
                    <a
                      href={userDetails?.fetchUser?.certificate_pdf}
                      target="_blank"
                      rel="noreferrer"
                      download={userDetails?.fetchUser?.certificate_pdf}
                    >
                      <Button
                        className="btn-bg"
                        shape="round"
                        icon={<DownloadOutlined />}
                        size="large"
                      >
                        Download Certificate PDF
                      </Button>
                    </a>
                  }
                ></Card>
              </Card>
            ) : (
              <NotAvailablePDf name="Certificate PDF Not Available" />
            )}
          </Col>

          <Col
            xs={{
              span: 24,
            }}
            xl={{
              offset: 2,
              span: 4,
            }}
            lg={{
              span: 8,
            }}
            md={{
              span: 12,
            }}
            className="dashbord-grid div3"
          >
            {userDetails?.fetchUser?.invoice_pdf ? (
              <Card bordered={true} hoverable>
                <Card
                  className="dashboardCard card3"
                  bordered={false}
                  cover={
                    <a
                      href={userDetails?.fetchUser?.invoice_pdf}
                      target="_blank"
                      rel="noreferrer"
                      download={userDetails?.fetchUser?.invoice_pdf}
                    >
                      <Button
                        className="btn-bg"
                        shape="round"
                        icon={<DownloadOutlined />}
                        size="large"
                      >
                        Download Invoice PDF
                      </Button>
                    </a>
                  }
                ></Card>
              </Card>
            ) : (
              <NotAvailablePDf name="Invoice PDF Not Available" />
            )}
          </Col>

          <Col
            xs={{
              span: 24,
            }}
            xl={{
              offset: 2,
              span: 4,
            }}
            lg={{
              span: 6,
            }}
            md={{
              span: 12,
            }}
            className="dashbord-grid div3"
          >
            {userDetails?.fetchUser?.application_form_pdf ? (
              <Card bordered={true} hoverable>
                <Card
                  className="dashboardCard card4"
                  bordered={false}
                  cover={
                    <a
                      href={userDetails?.fetchUser?.application_form_pdf}
                      target="_blank"
                      rel="noreferrer"
                      download={userDetails?.fetchUser?.application_form_pdf}
                    >
                      <Button
                        className="btn-bg"
                        shape="round"
                        icon={<DownloadOutlined />}
                        size="large"
                      >
                        Application PDF
                      </Button>
                    </a>
                  }
                ></Card>
              </Card>
            ) : (
              <NotAvailablePDf name="Application PDF Not Available" />
            )}
          </Col>
        </Row>
      </div>
    </>
  )
}

// State
const mapStateToProps = state => {
  return {
    userDetails: state?.userData?.userDetails,
  }
}

export default connect(mapStateToProps, { getUserDetailsAction })(Dashboard)
