import { USER } from '../constants'
import {
  otpSend,
  registerUser,
  renewUser,
  stateList,
  signIn,
  getUserDetails,
  printPdfAction,
  settingData,
} from '../../services/user'
import { message } from 'antd'
import jwt from 'jwt-decode'
import moment from 'moment'

const {
  REGISTRATIONLOADER,
  STATELIST,
  SENDOTP,
  VERIFYOTP,
  GETUSERDETAILS,
  SAVEMOBILE,
  PRINT_LOADER,
  PRINT_DATA_STORE,
  SETTING_DATA_STORE,
  SET_IS_CAPTCHA_VERIFY_LOGIN,
  SET_IS_CAPTCHA_VERIFY_OTP,
  STORE_VALID_TILL_DATE,
} = USER

export function setLoaderAtUserRegistrationTime(data = {}) {
  return { type: REGISTRATIONLOADER, data }
}

export function setLoaderAtUserPrintTime(data = {}) {
  return { type: PRINT_LOADER, data }
}
export function storeDataPDF(data = {}) {
  return { type: PRINT_DATA_STORE, data }
}

export function storeValidTillDate(data = {}) {
  return { type: STORE_VALID_TILL_DATE, data }
}

export function printPdf(payload) {
  return async dispatch => {
    dispatch(setLoaderAtUserPrintTime(true))
    return await printPdfAction(payload?.formData2)
      .then(result => {
        if (result?.data?.code == 201) {
          dispatch(storeDataPDF(result))
          dispatch(setLoaderAtUserPrintTime(false))
        }
      })
      .catch(error => {
        let errMsg = error.response.data ? error.response.data : error.message
        if (errMsg.code) {
          message.error(errMsg.message)
        } else {
          message.error(error && error.message)
        }
        dispatch(setLoaderAtUserPrintTime(false))
      })
  }
}

export function userRegistrationAction(params) {
  return async dispatch => {
    dispatch(setLoaderAtUserRegistrationTime(true))
    return await registerUser(params.formData)
      .then(res => {
        dispatch(setLoaderAtUserRegistrationTime(false))
        if (res.status === 200) {
          dispatch(storeValidTillDate(res?.data?.data?.valid_till))
          message.success('User registration successful.')

          params?.navigate(
            `success/${params.stateTwoLetter}${params.TwoLetterStateNumber}${params.aadharcard_number}`
          )
        }
      })
      .catch(error => {
        let errMsg = error.response.data ? error.response.data : error.message
        if (errMsg.code) {
          message.error(errMsg.message)
        } else {
          message.error(error && error.message)
        }
        dispatch(setLoaderAtUserRegistrationTime(false))
      })
  }
}

// Renew
export function userRenewAction(params) {
  return async dispatch => {
    dispatch(setLoaderAtUserRegistrationTime(true))
    return await renewUser(params.formData)
      .then(res => {
        dispatch(setLoaderAtUserRegistrationTime(false))
        if (res?.status === 200) {
          dispatch(storeValidTillDate(res?.data?.data?.valid_till))
          message.success('User Renew successful.')

          params?.navigate(
            `success/${params.stateTwoLetter}${params.TwoLetterStateNumber}${params.aadharcard_number}`
          )
        }
      })
      .catch(error => {
        dispatch(setLoaderAtUserRegistrationTime(false))

        // console.log(error?.message, '<<--error?.message')

        let errMsg = error?.response?.data ? error?.response?.data : error?.message
        if (errMsg?.code) {
          message.error(errMsg.message)
        } else {
          message.error(error && error?.message)
        }
      })
  }
}

export function optSend(data = {}) {
  return { type: SENDOTP, data }
}

export function saveMobile(params) {
  return { type: SAVEMOBILE, params }
}

export function otpSendAction(params) {
  return async dispatch => {
    dispatch(optSend(true))
    return await otpSend(params?.value)
      .then(res => {
        dispatch(optSend(false))
        if (res.status === 200) {
          dispatch(setIsCaptchaVerifyLogin(true))
          message.success(res.data.message)
          dispatch(saveMobile(params?.value.mobile))
          params?.navigate(`/verifyOtp`)
        }
      })
      .catch(error => {
        let errMsg = error.response.data ? error.response.data : error.message
        if (errMsg.code) {
          message.error(errMsg.message)
          window.grecaptcha.reset()
          dispatch(setIsCaptchaVerifyLogin(false))
        } else {
          message.error(error && error.message)
        }
        dispatch(optSend(false))
      })
  }
}

export function setIsCaptchaVerifyLogin(data = {}) {
  return { type: SET_IS_CAPTCHA_VERIFY_LOGIN, data }
}

export function verifyOtp(data = {}) {
  return { type: VERIFYOTP, data }
}

export function setIsCaptchaVerifyOtp(data = {}) {
  return { type: SET_IS_CAPTCHA_VERIFY_OTP, data }
}

export function verifyOtpAction(params) {
  return async dispatch => {
    dispatch(verifyOtp(true))
    return await signIn(params?.verifyData)
      .then(res => {
        dispatch(verifyOtp(false))
        if (res.status === 200) {
          dispatch(setIsCaptchaVerifyOtp(true))
          SaveDateTimeInToken(res.data.data.token)
          window.localStorage.setItem('token', res.data.data.token)
          window.localStorage.setItem('userId', res.data.data._id)
          message.success('Your mobile number is verified')
          params?.navigate('/dashboard', { replace: true })
        }
      })
      .catch(error => {
        let errMsg = error.response.data ? error.response.data : error.message
        if (errMsg.code) {
          window.grecaptcha.reset()
          message.error(errMsg.message)
        } else {
          message.error(error && error.message)
        }
        dispatch(setIsCaptchaVerifyOtp(false))
        dispatch(verifyOtp(false))
      })
  }
}

export function userDetail(data = {}) {
  return { type: GETUSERDETAILS, data }
}

export function getUserDetailsAction(params) {
  return async dispatch => {
    const result = await getUserDetails(params)
    if (result.data.code === 200) {
      dispatch(userDetail(result.data.data))
    }
  }
}

export function setStateList(data = {}) {
  return { type: STATELIST, data }
}

export function getStateList() {
  return async dispatch => {
    return await stateList()
      .then(res => {
        if (res.status === 200) {
          const data = res.data && res.data.data && res.data.data ? res.data.data : []
          dispatch(setStateList(data))
        }
      })
      .catch(_error => {
        // console.log('error: ', error)
      })
  }
}

export const SaveDateTimeInToken = token => {
  let user = jwt(token)
  let expiryDate = new Date(user.exp)
  let DateTimeToken = moment(new Date(expiryDate)).format('YYYY-MM-DD HH:mm:ss')
  localStorage.setItem('DateTimeTokenFront', DateTimeToken)
}

export function GetSettingData() {
  return async dispatch => {
    return await settingData()
      .then(res => {
        if (res.status === 200) {
          const data = res.data && res.data.data && res.data.data ? res.data.data : []
          dispatch(setSettingData(data))
        }
      })
      .catch(_error => {
        // console.log('error: ', error)
      })
  }
}

export function setSettingData(data = {}) {
  return { type: SETTING_DATA_STORE, data }
}
